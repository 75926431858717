"use client"

import { Score } from "@/lib/schema";
import { Loader } from "lucide-react";
import useSWR from "swr";
import { useAccount } from "wagmi";
import Header from "./Header";

type PersonalScore = {
    data: Score
    index: number
}

function UserPosition({ tournamentId }: { tournamentId: number }) {
    const { address } = useAccount()
    const fetcher = (url: string) => fetch(url).then((res) => res.json());

    const { data, isLoading }: { data: PersonalScore, isLoading: boolean } = useSWR(
        `${process.env.NEXT_PUBLIC_API_ENDPOINT}/leaderboard?tournamentId=${tournamentId}&wallet=${address}`,
        fetcher
    )

    if (isLoading) return <Loader className="animate-spin m-auto" />

    return (
        <div className="user-position flex flex-col card-border bg-card">
            <Header heading="YOUR POSITION" showSection={true} />
            <div className="flex-grow crt-texture">
                <div className="flex flex-col items-center justify-center h-full p-6 sm:p-8">
                    {!address ? (
                        <div className="text-center">
                            <p className="text-lg sm:text-xl">Connect to view your position</p>
                        </div>
                    ) : data && (
                        <div className="text-center">
                            <h3 className="text-3xl sm:text-4xl mb-2">Position: {data.index || "?"}</h3>
                            <p className="text-lg sm:text-xl">Score: {data.data?.value || "NO_SCORE"}</p>
                        </div>
                    )}
                </div>
            </div>
        </div>
    )
}

export default UserPosition