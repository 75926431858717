"use client"

import { ConnectButton } from '@rainbow-me/rainbowkit';
import Image from 'next/image';
import { Button } from './ui/button';

export const CustomConnectButton = () => {
    return (
        <ConnectButton.Custom>
            {({
                account,
                chain,
                openAccountModal,
                openChainModal,
                openConnectModal,
                authenticationStatus,
                mounted,
            }) => {
                const ready = mounted && authenticationStatus !== 'loading';
                const connected =
                    ready &&
                    account &&
                    chain &&
                    (!authenticationStatus ||
                        authenticationStatus === 'authenticated');


                const _openConnectModal = () => {
                    openConnectModal();
                };

                return (
                    <div
                        {...(!ready && {
                            'aria-hidden': true,
                            'style': {
                                opacity: 0,
                                pointerEvents: 'none',
                                userSelect: 'none',
                            },
                        })}
                    >
                        {(() => {
                            if (chain?.unsupported) {
                                return (
                                    <Button className="w-full" variant={'secondary'} onClick={openChainModal} type="button">
                                        Wrong network
                                    </Button>
                                );
                            }
                            if (!connected) {
                                return (
                                    <Button className="w-full animate-pulse" variant={'secondary'} onClick={_openConnectModal} type="button">
                                        Connect
                                    </Button>
                                );
                            }

                            return (
                                <div className="w-full" style={{ display: 'flex', gap: 12 }}>
                                    <Button
                                        className="w-full"
                                        variant={'secondary'}
                                        onClick={openChainModal}
                                        style={{ display: 'flex', alignItems: 'center' }}
                                        type="button"
                                    >
                                        {chain.hasIcon && (
                                            <div
                                                style={{
                                                    background: chain.iconBackground,
                                                    width: 12,
                                                    height: 12,
                                                    borderRadius: 999,
                                                    overflow: 'hidden',
                                                    marginRight: 4,
                                                }}
                                            >
                                                {chain.iconUrl && (
                                                    <Image
                                                        height={12}
                                                        width={12}
                                                        alt={chain.name ?? 'Chain icon'}
                                                        src={chain.iconUrl}
                                                    />
                                                )}
                                            </div>
                                        )}
                                        {chain.name}
                                    </Button>
                                    <Button className="w-full" variant={'secondary'} onClick={openAccountModal} type="button">
                                        {account.displayName}
                                        {account.displayBalance
                                            ? ` (${account.displayBalance})`
                                            : ''}
                                    </Button>
                                </div>
                            );
                        })()}
                    </div>
                );
            }}
        </ConnectButton.Custom>
    );
};