"use client"

import { Tournament } from '@/lib/schema';
import { LayoutGrid, List } from 'lucide-react';
import { useEffect, useState } from 'react';
import { FilteredToken } from './Dashboard';
import TokenSelector from './TokenSelector';
import TournamentCarousel from './TournamentCarousel';
import TournamentList from './TournamentList';
import { IconButton } from './ui/icon-button';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from './ui/select';

interface TournamentDisplayProps {
    tournaments: Tournament[];
    tournamentId: number;
    token: string | null;
    userId: string | null;
    network: string;
    filteredTokens: FilteredToken[];
}

type FilterOption = 'all' | 'newest' | 'oldest' | 'communityTournaments' | 'metacadeTournaments' | 'myTournaments'

export default function TournamentDisplay({ tournaments, tournamentId, token, userId, network, filteredTokens }: TournamentDisplayProps) {
    const [viewMode, setViewMode] = useState<'carousel' | 'list'>(() => {
        // Initialize from localStorage or default to 'carousel'
        if (typeof window !== 'undefined') {
            return (localStorage.getItem('tournamentViewMode') as 'carousel' | 'list') || 'carousel';
        }
        return 'carousel';
    });
    const [filterOption, setFilterOption] = useState<FilterOption>('all');

    // Remove all 'COMPLETE' tournaments from the list
    const _filteredTournaments = tournaments.filter(tournament => tournament.status !== 'COMPLETE');
    const filteredTournaments = filterTournaments(_filteredTournaments, filterOption, userId);

    useEffect(() => {
        // Update localStorage when viewMode changes
        localStorage.setItem('tournamentViewMode', viewMode);
    }, [viewMode]);

    return (
        <div className="h-full flex flex-col">
            <div className="flex flex-wrap items-center justify-between gap-2 mb-4 px-4">
                <div className="flex flex-1 items-center gap-2 min-w-0">
                    <Select onValueChange={(value: FilterOption) => setFilterOption(value)} defaultValue="all">
                        <SelectTrigger className="w-[120px] sm:w-[180px]">
                            <SelectValue placeholder="Filter Tournaments" />
                        </SelectTrigger>
                        <SelectContent>
                            <SelectItem value="all">All Tournaments</SelectItem>
                            <SelectItem value="newest">Newest to Oldest</SelectItem>
                            <SelectItem value="oldest">Oldest to Newest</SelectItem>
                            <SelectItem value="communityTournaments">Community Tournaments</SelectItem>
                            <SelectItem value="metacadeTournaments">Metacade Tournaments</SelectItem>
                            <SelectItem value="myTournaments">My Tournaments</SelectItem>
                        </SelectContent>
                    </Select>
                    <TokenSelector tokens={filteredTokens} />
                    {/* <NetworkSelector network={network} /> */}
                </div>
                <IconButton
                    variant="outline"
                    size="sm"
                    onClick={() => setViewMode(viewMode === 'carousel' ? 'list' : 'carousel')}
                    aria-label={viewMode === 'carousel' ? 'Switch to List View' : 'Switch to Carousel View'}
                >
                    {viewMode === 'carousel' ? <List size={20} aria-hidden="true" /> : <LayoutGrid size={20} aria-hidden="true" />}
                    <span className="sr-only">{viewMode === 'carousel' ? 'Switch to List View' : 'Switch to Carousel View'}</span>
                </IconButton>
            </div>
            <div className="flex-grow overflow-hidden">
                {viewMode === 'carousel' ? (
                    <TournamentCarousel tournaments={filteredTournaments} tournamentId={tournamentId} token={token} />
                ) : (
                    <div className="h-full">
                        <TournamentList tournaments={filteredTournaments} tournamentId={tournamentId} token={token} />
                    </div>
                )}
            </div>
        </div>
    );
}

function filterTournaments(tournaments: Tournament[], option: FilterOption, userId: string | null): Tournament[] {
    switch (option) {

        case 'newest':
            return [...tournaments].sort((a, b) => new Date(b.startedAt || '').getTime() - new Date(a.startedAt || '').getTime());
        case 'oldest':
            return [...tournaments].sort((a, b) => new Date(a.startedAt || '').getTime() - new Date(b.startedAt || '').getTime());
        case 'communityTournaments':
            return tournaments.filter(tournament => tournament.createdBy != '' && tournament.createdBy != null);
        case 'metacadeTournaments':
            return tournaments.filter(tournament => !tournament.createdBy);
        case 'myTournaments':
            return tournaments.filter(tournament => tournament.createdBy == userId);
        default:
            return tournaments;
    }
}