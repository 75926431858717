
function Header({ heading, showSection, children }: { heading: string, showSection: boolean, children?: React.ReactNode }) {
    return (
        <div className=" px-4 py-4 border-b border-[#826dff]  w-full  flex justify-between bg-card"> <p className="text-sm futuristic-text uppercase font-heading">{heading}</p>
            {
                showSection ? (
                    <div className="flex items-center">
                        <span className="h-2 w-8 mr-1 bg-[#39184D] p-2"></span>
                        <span className="h-2 w-8 mr-1 bg-[#39184D] p-2"></span>
                        <span className="h-2 w-8 mr-1 bg-[#39184D] p-2"></span>
                        <span className="h-2 w-8 mr-1 bg-[#39184D] p-2"></span>
                        <span className="ml-2 relative flex h-3 w-3">
                            <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-pink-400 opacity-75"></span>
                            <span className="relative inline-flex rounded-full h-3 w-3 bg-pink-500"></span>
                        </span>
                    </div>
                ) : children ? children : null
            }
        </div>
    )
}

export default Header