"use client"

import useClerkSWR from "@/hooks/useClerkHook";
import { CreditData } from "@/lib/utils";
import { useUser } from "@clerk/nextjs";
import { sendGAEvent } from "@next/third-parties/google";
import { useMemo, useState } from "react";
import { useAccount } from "wagmi";
import { CREDITS_KEY } from "./CreditsBox";
import { Button } from "./ui/button";

function JoinTournamentButton({ gameId, tournamentId, hasCompleted, token, paymentTokenAddress }: { gameId: number, tournamentId: number, hasCompleted: boolean, token: string, paymentTokenAddress: string }) {
    const [loaded, setIsLoaded] = useState<boolean>(false)
    const { address, isConnected } = useAccount()
    const { isSignedIn, user } = useUser()
    const url = `${process.env.NEXT_PUBLIC_BASE_SITE_URL}/game/${gameId}?tid=${tournamentId}`

    const fetcher = (url: string) => fetch(url, {
        method: "GET",
        headers: {
            Authorization: `Bearer ${token}` as string
        }
    }).then((res) => res.json());

    const { data: creditData, isLoading: creditDataLoading }: { data: CreditData, isLoading: boolean } = useClerkSWR(
        CREDITS_KEY(tournamentId)
    )

    const hasMinRequiredCredits = useMemo(() => {
        if (!creditData || !isSignedIn) return false
        return creditData.data?.balance >= creditData.data?.balanceMin && creditData.data?.balanceMin != -1
    }, [creditData, isSignedIn])

    const buttonLabel = useMemo(() => {
        setIsLoaded(true)
        if (hasCompleted) return 'Completed'
        if (!address && !isConnected) return 'Connect Wallet'
        if (!hasMinRequiredCredits) return 'No Credits'
        return 'Join'
    }, [hasCompleted, hasMinRequiredCredits, address, isConnected])

    const buttonDisabled = useMemo(() => {
        if (hasCompleted) return true
        if (!hasMinRequiredCredits) return true
        if (!address && !isConnected) return true
        return false
    }, [hasCompleted, hasMinRequiredCredits, address, isConnected])

    if (creditDataLoading || !loaded) return (
        <Button variant="secondary" disabled={true} className="text-white font-bold mb-4 py-2 px-4 mt-4 rounded-xl w-full">
            Loading..
        </Button>
    )

    return (
        <Button variant="secondary" disabled={buttonDisabled} onClick={() => {
            if (!gameId) return
            // Open the game in a new tab
            sendGAEvent('event', 'play_tournament_button', { wallet: address, email: user?.primaryEmailAddress?.emailAddress, username: user?.username, tournamentId: tournamentId })

            console.log(`Opening game in new tab: ${url}`)
            window.open(url, '_blank', 'noreferrer')
        }} className="text-white font-bold mb-4 py-2 px-4 mt-4 rounded-xl w-full hover:border-2 hover:border-secondary-foreground hover:animate-pulse">
            {buttonLabel}
        </Button>
    )
}

export default JoinTournamentButton