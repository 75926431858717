"use client";

import { Wallet2Icon } from "lucide-react";
import { useAccount } from "wagmi";
import { Button } from "./ui/button";

const SmartWallet: React.FC = () => {
    const { isConnected, connector } = useAccount()
    return (
        <>
            {isConnected && connector?.name === 'Coinbase Wallet' && (
                <div className="flex justify-center w-full">
                    <Button
                        variant={'secondary'}
                        className="w-full"
                        onClick={() => window.open('https://wallet.coinbase.com/assets', '_blank', 'noreferrer')}
                    >
                        <Wallet2Icon className="mr-2" />
                        Smart Wallet
                    </Button>
                </div>
            )}
        </>
    )
}

export default SmartWallet;